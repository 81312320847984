import React, { useReducer } from "react";
import isEmpty from "../components/utils/isEmpty";
export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

export const ACTIONS = {
  SET_COOKIE_CONSENT: "SET_COOKIE_CONSENT",
  SET_GOOGLE_ANALYTICS: "SET_GOOGLE_ANALYTICS",
  SHOW_COOKIE_PREFERENCES: "SHOW_COOKIE_PREFERENCES",
  SHOW_PAGE_LOADING: "SHOW_PAGE_LOADING",
};

const initialState = {
  acceptCookie:
    typeof window !== "undefined" &&
    !isEmpty(localStorage.getItem("cookie_consent"))
      ? localStorage.getItem("cookie_consent")
      : true,
  enableAnalytics:
    typeof window !== "undefined" &&
    !isEmpty(localStorage.getItem("enable_analytics"))
      ? localStorage.getItem("enable_analytics")
      : true,
  showCookiePreferences: false,
  showPageLoading: true,
  containerAnimationDelay: 2,
};

function reducer(state, action) {
  let { type, payload } = action;
  switch (type) {
    case ACTIONS.SET_COOKIE_CONSENT:
      if (typeof window !== "undefined") {
        localStorage.setItem("cookie_consent", true);
      }
      return {
        ...state,
        acceptCookie: true,
      };
    case ACTIONS.SET_GOOGLE_ANALYTICS:
      if (typeof window !== "undefined") {
        localStorage.setItem("enable_analytics", payload);
      }
      return {
        ...state,
        enableAnalytics: payload,
      };
    case ACTIONS.SHOW_COOKIE_PREFERENCES:
      return {
        ...state,
        showCookiePreferences: !state.showCookiePreferences,
      };
    case ACTIONS.SHOW_PAGE_LOADING:
      return {
        ...state,
        showPageLoading: false,
      };

    default:
      return state;
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // useEffect(() => {
  //   if (
  //     !initialState.enableAnalytics ||
  //     initialState.enableAnalytics === "false"
  //   ) {
  //     window["ga-disable-UA-192441462-1"] = true;
  //   }
  // }, []);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;
